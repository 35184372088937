<template>
  <div ref="activity" class="activity">
    <Baberrage v-if="activity.activityStatus === 0" :activity-id="activity.id" />
    <van-icon name="user-o" class="ucenter" @click="toUcenter" />
    <music-note v-if="activity.bgmBtnSwitch" :mp3-path="activity.bgmPath" :position="activity.bgmBtnPosition" />
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item v-for="(item, idx) in coverList" :key="idx">
        <img :src="item" />
      </van-swipe-item>
    </van-swipe>

    <div class="activity_info">
      <div class="title">{{ activity.title }}</div>
      <div class="descn">{{ activity.descn }}</div>
      <div class="price">
        <span class="orig_price">{{ activity.price | formatMoney }}</span>
        <!-- <span class="line_price">{{ groupOn.linePrice | formatMoney }}</span> -->
      </div>
      <div class="item">
        <span class="label">活动时间：</span><span class="value">{{ activity.startTime }} 至 {{ activity.endTime }}</span>
      </div>
      <div class="item">
        <span class="label">剩余时间：</span><span class="value">
          <CountDown v-if="activity.endTime" :end-time="activity.endTime" />
        </span>
      </div>
    </div>

    <!-- 未购买 -->
    <div v-if="!joinFlag" class="as-leader">
      <div class="tips">
        <ul>
          <li>欢迎参与【{{ curTeam.wxUser.nickname }}】发起的拼团活动。您可以：</li>
          <li>1. 点击页面底部的“参加拼团”，加入到TA拼团活动。</li>
          <li>2. 点击页面底部的“我要开团”，重新开团，自己做团长。</li>
          <li>3. 将本页面分享给微信好友，一起参与TA的拼团活动;</li>
          <li>4. 点击“生成海报”并分享给微信好友。</li>
        </ul>
      </div>
    </div>
    <div v-else class="as-leader">
      <div class="tips">
        <ul>
          <li v-if="teamLeaderFlag">您已经开团成功。可以邀请好友一起参与拼团活动：</li>
          <li v-else>您已经成功参与【{{ curTeam.wxUser.nickname }}】发起的拼团活动。您可以邀请好友一起参与TA的拼团活动：</li>
          <li>1. 将本页面分享给微信好友;</li>
          <li>2. 点击“生成海报”并分享给微信好友。</li>

          <li v-if="repeatOrderFlag">您还可以点击页面底部的“我要开团”，重新开团，自己做团长。</li>
        </ul>
      </div>
    </div>

    <div class="team-members">
      <div class="title">拼团成员</div>
      <van-grid :column-num="5" :border="false">
        <van-grid-item v-for="(item,idx) in memberList" :key="idx">
          <van-badge v-if="item.leaderFlag" :content="'队长'">
            <img :src="item.avatar" class="avatar" />
          </van-badge>
          <img v-else :src="item.avatar" class="avatar" />
        </van-grid-item>
      </van-grid>
    </div>

    <div ref="activityContent" class="activity-content" />

    <van-cell-group v-if="formItemList.length > 0" class="ext-data-form-container">
      <div v-for="(item,idx) in formItemList" :key="idx">
        <TextField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" :maxlength="item.maxLength" />
        <MobileField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" />
        <AgeField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" />
        <DatetimePickerField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" />
        <RadioField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :options="item.optionsContent" :required="item.required" />
        <PickerField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :options="item.optionsContent" :required="item.required" />
        <CheckboxField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :options="item.optionsContent" :required="item.required" />
      </div>
    </van-cell-group>

    <div class="copyright">--==易办学==--</div>

    <van-goods-action v-if="activity.activityStatus === 0 && !joinFlag">
      <!--活动可用-->
      <!--加入团购-->
      <van-goods-action-button type="primary" text="我要开团当团长" @click="createNew" />
      <van-goods-action-button slot-scope="" type="danger" text="我不开团只购买" @click="buyNow" />
    </van-goods-action>

    <van-goods-action v-if="activity.activityStatus === 0 && joinFlag ">
      <!--已购买&&可重复购买-->
      <van-goods-action-button v-if=" repeatOrderFlag" type="primary" text="我要开团当团长" @click="createNew" />
      <van-goods-action-button v-else type="primary" text="您已经参团" />
    </van-goods-action>

    <van-goods-action v-if="activity.activityStatus === -1">
      <!--活动其他状态-->
      <van-goods-action-button type="danger" text="团购未开始" />

    </van-goods-action>
    <van-goods-action v-if="activity.activityStatus === 1">
      <!--活动其他状态-->
      <van-goods-action-button type="danger" text="团购已结束" />
    </van-goods-action>

    <SharePoster v-if="activity.activityStatus ===0 && activity.shareBtnSwitch && teamId > 0" :team-id="teamId" :aid="activity.id" atype="groupOn"
      :position="activity.shareBtnPosition" sharp="square" />
  </div>
</template>
<script>
import wxconfig from '@/wx'
import groupOnApi from '@/api/group-on'

import wxComPayApi from '@/api/wxcom-pay'

import MusicNote from '@/views/components/MusicNote.vue'
import SharePoster from '@/views/components/SharePoster.vue'

import TextField from '@/views/components/TextField.vue'
import AgeField from '@/views/components/AgeField.vue'
import MobileField from '@/views/components/MobileField.vue'
import RadioField from '@/views/components/RadioField.vue'
import CheckboxField from '@/views/components/CheckboxField.vue'
import PickerField from '@/views/components/PickerField.vue'
import DatetimePickerField from '@/views/components/DatetimePickerField.vue'

import Baberrage from '@/views/components/Baberrage.vue'

import CountDown from '@/views/components/CountDown.vue'
import { isOnline } from '@/utils/comm'
export default {
  components: {
    MusicNote,
    SharePoster,
    MobileField,
    TextField,
    AgeField,
    PickerField,
    RadioField,
    DatetimePickerField,
    CheckboxField,
    Baberrage,
    CountDown
  },
  data() {
    return {
      appId: '',
      teamId: -1,
      curTeam: {
        wxUser: {
          nickname: ''
        }
      },
      teamLeaderFlag: false, // 队长标识
      joinFlag: false, // 参与活动标识
      activity: {},
      coverList: [],
      btnBuyingFlag: false, // 已点击购买按钮，正在购买标识
      memberList: [],
      formItemList: [],
      extDataForm: {}, // 自定义表单
      refereeUserId: '' // 推荐人的userId
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.activity.id = parseInt(this.$route.params.id)
    this.teamId = parseInt(this.$route.params.teamId)
    this.refereeUserId = this.$route.query.ruId
    this.initActivityData()
  },
  mounted() {},
  methods: {
    /* 校验表单信息
    validateExtDataForm() {
      let keyArr = Object.keys(this.extDataForm)

      let formItemIdArr = []
      this.formItemList.forEach(i => {
        if (i.required) {
          // 必填
          formItemIdArr.push(i.title)
        }
      })

      // 校验字段必填
      for (let i = 0; i < formItemIdArr.length; i++) {
        if (keyArr.indexOf(formItemIdArr[i]) === -1) {
          let formItem = this.getFormItemById(formItemIdArr[i])
          if (formItem.dataType === 'checkbox' || formItem.dataType === 'radio') {
            this.$toast('请选择' + formItem.title)
            return false
          } else {
            this.$toast('请填写' + formItem.title)
            return false
          }
        }
      }
      return true
    },
    getFormItemById(id) {
      for (let i = 0; i < this.formItemList.length; i++) {
        if (this.formItemList[i].title === id) {
          return this.formItemList[i]
        }
      }
    },
    buyNow2() {
      let validateResult = this.validateExtDataForm()
      if (!validateResult) {
        // 滚动到表单位置
        const formContainer = document.querySelector('.ext-data-form-container')
        formContainer.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
        // document.documentElement.scrollTop = this.$refs.activity.scrollHeight
      } else {
        console.log(JSON.stringify(this.extDataForm))
        this.buyNow()
      }
    },*/
    // 直接下单逻辑
    buyNow() {
      if (this.btnBuyingFlag) {
        return false
      }
      this.btnBuyingFlag = true
      let data = {
        activityId: this.activity.id,
        p2: this.teamId,
        refereeUserId: this.refereeUserId,
        extFormData: JSON.stringify(this.extDataForm)
      }

      wxComPayApi.groupOnBuy(data).then(res => {
        // this.$toast(JSON.stringify(res))
        if (res.succ) {
          let data = res.data
          if (data.nextCode !== '00') {
            // 根据nextCode分流
            this.redirectByNextCode(data)
          } else {
            this.$wx.chooseWXPay({
              appId: data.appId,
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: data.signType,
              paySign: data.paySign,
              success: res => {
                // 支付成功后跳转，已经被微信“点金计划”阻止，不可自定义支付后跳转
                // this.$router.push(`/groupOnResult/${this.appId}/${this.activity.id}/${data.teamId}`)
              }
            })
          }
          this.btnBuyingFlag = false
        }
      })
    },
    redirectByNextCode(data) {
      let nextCode = data.nextCode
      if (nextCode === '04') {
        // 自定义表单错误，编码：04，使用toast进行提示
        const formContainer = document.querySelector('.ext-data-form-container')
        formContainer.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
        this.$toast(data.msg)
      } else {
        // 其他错误。使用alert进行提示
        this.$dialog
          .alert({
            title: '提示',
            message: data.msg,
            theme: 'round-button'
          })
          .then(() => {
            if (data.url) {
              // 如果有跳转链接，就跳转
              this.$router.push(data.url)
            }
          })
      }
    },
    initWxConfig() {
      // 配置微信信息
      if (isOnline()) {
        wxconfig([this.initShareData])
      }
    },
    // 加载活动信息
    initActivityData() {
      groupOnApi.get(this.activity.id, this.teamId).then(({ succ, data }) => {
        if (succ) {
          if (!data.team) {
            this.$toast('团队不存在')
          } else {
            this.joinFlag = data.joinFlag
            this.repeatOrderFlag = data.repeatOrderSwitch
            // 如果可重复购买，不考虑是否已购买，都不跳转
            if (this.repeatOrderFlag) {
              // 渲染页面
              this.randerActivityPage(data)
            } else {
              // 如果不可重复购买
              if (this.joinFlag) {
                // 1.判断是否已经购买，已经 购买则跳转到队伍
                let memberInTeamId = data.team.id
                if (memberInTeamId !== -1 && memberInTeamId !== this.teamId) {
                  // 说明当前登录用户已经参与过团购,跳转到登录用户参与的团购加入页面
                  console.log('跳转到teamId', memberInTeamId)
                  window.location.href = `/${this.appId}/groupOn/join/${this.activity.id}/${memberInTeamId}`
                } else {
                  this.randerActivityPage(data)
                }
              } else {
                // 2.未购买，不跳转,渲染页面
                this.randerActivityPage(data)
              }
            }
          }
        } else {
          this.$toast('活动不存在')
        }
      })
    },
    randerActivityPage(data) {
      this.activity = data.item
      // 初始化轮播图列表
      this.coverList.push(data.item.cover)
      // 活动详情
      this.$refs.activityContent.innerHTML = data.item.content
      if (this.activity.customFormSwitch) {
        // 自定义表单内容
        this.formItemList = data.formItemList
      }
      this.teamLeaderFlag = data.teamLeaderFlag
      // 加载团队&队员信息
      this.curTeam = data.team
      this.memberList = data.teamMemberList
      // 初始化微信相关
      this.initWxConfig()
    },
    // 开新团
    createNew() {
      // 开新团时，如果有推广人员信息也传入
      this.$router.push(`/${this.appId}/groupOn/detail/${this.activity.id}/-1?ruId=${this.refereeUserId}`)
    },
    toUcenter() {
      this.$router.push(`/${this.appId}/ucenter`)
    },
    initShareData() {
      // 此页面使用微信分享时，有团队teamId信息
      let userInfoId = this.userInfo.id
      let link = process.env.VUE_APP_BASE_URL + `/${this.appId}/groupOn/join/${this.activity.id}/${this.teamId}?ruId=${userInfoId}`
      let miniCover = `http://img.cover.c60rock.com/market_activity_${this.activity.id}.jpg`
      let shareOptions = {
        title: this.activity.title,
        desc: this.activity.descn,
        link: link,
        imgUrl: miniCover
      }
      // 分享给好友
      this.$wx.updateAppMessageShareData(shareOptions)
      // 分享到朋友圈
      this.$wx.updateTimelineShareData(shareOptions)
    }
  }
}
</script>
<style lang="less" scoped>
.activity {
  position: relative;
  background-color: #fff;
  .ucenter {
    position: fixed;
    top: 0.2rem;
    right: 0.1rem;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 1rem;
    color: #666;
    background-color: #ccc;
    border-radius: 50%;
    z-index: 10002;
    opacity: 0.7;
    &.van-icon::before {
      padding-left: 0.1rem;
      padding-top: 0.05rem;
    }
  }
  .my-swipe {
    height: 280px;
    img {
      width: 100%;
      height: 280px;
      display: block;
    }
  }

  .activity_info {
    padding: 0.2rem;
    position: relative;
    font-size: 0.36rem;
    .title {
      font-size: 22px;
      min-height: 28px;
      line-height: 28px;
      font-weight: normal;
    }
    .descn {
      margin: 6px 0;
      font-size: 14px;
      color: #888;
    }
    .price {
      margin: 6px 0;
      .orig_price {
        color: darkred;
        font-size: 20px;
        font-weight: bold;
      }
      .line_price {
        padding-left: 10px;
        color: #aaa;
        font-size: 12px;
        text-decoration: line-through;
      }
    }
    .item {
      height: 0.8rem;
      line-height: 0.8rem;
      .label {
        width: 1rem;
      }
      .value {
        color: #999;
        width: 1rem;
        text-align: left;
      }
    }
  }
  .tips {
    font-size: 0.36rem;
    font-weight: 600;
    color: darkred;
    padding: 0.2rem;
    border: 1px dashed darkred;
    margin-bottom: 0.2rem;
    ul {
      li {
        line-height: 0.5rem;
      }
    }
  }
  .as-leader {
    background-color: #fff;
    padding: 0 0.2rem 0.2rem 0.2rem;
  }
  .team-members {
    background-color: #fff;
    padding: 0 0.2rem 0.2rem 0.2rem;
    .title {
      border-left: 8px solid darkred;
      font-size: 0.4rem;
      font-weight: 500;
      padding-left: 0.1rem;
      margin-bottom: 0.2rem;
    }
    .van-grid {
      border: 1px dashed darkred;
      min-height: 1.6rem;
      /deep/ .van-grid-item__content {
        padding: 0.2rem;
      }
      img.avatar {
        border: 1px solid #999;
        border-radius: 50%;
        width: 1.4rem;
        height: 1.4rem;
      }
      /deep/ .van-badge--fixed {
        transform: translate(10%, 10%);
      }
    }
  }
  .activity-content {
    /deep/ p {
      margin: 0 auto;
      img {
        display: block;
      }
    }
  }
  .ext-data-form-container {
    margin: -0.2rem 0.2rem 0.2rem 0.2rem;
    border: 1px dashed darkred;
  }
}
.van-goods-action-button--first,
.van-goods-action-button--last {
  border-radius: 0;
}
.copyright {
  display: block;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
}
</style>
